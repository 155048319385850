export const texts = {
  searchInputPlaceholder: "Click to search movie",
  favHeading: "My Favourites",
  clearAll: "Clear All",
  noItem: "No items to list",
  headerFavourites: "Favourites",
  watchNow: "Watch Now",
  prevButton: "Previous Page",
  nextButton: "Next Page",
  genre: "Genre",
  length: "Length",
  price: "Price",
  public: "Public",
  genrePlaceholder: "Animation",
  lengthPlaceholder: "120min",
};
